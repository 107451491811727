import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

export default class Categories extends Component {
  render() {
    const { categories } = this.props;
    return (
      <>
        {categories.map(({ name, path }, i) => (
          <React.Fragment key={name}>
            {!!i && ", "}
            <Link to={path}>{name}</Link>
          </React.Fragment>
        ))}
      </>
    );
  }
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired
};
